import * as React from "react";
import { ReactDatePickerProps } from "react-datepicker";
import { FormGroup } from "./Group";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { ValidationStatus } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms/Form/_Types";

export interface FieldLabelValue {
    label: string;
    value?: string;
}

export interface FieldValueProps {
    options?: Mary.atoms.SelectOption[];
    value?: string | number;
    values?: string[] | Mary.atoms.SelectOption[];
    type?: string;
    min?: number | string;
    max?: number | string;
    minDate?: Date;
    maxDate?: Date;
    multiple?: boolean;
    title?: string;
    withPrefix?: boolean;
    withoutDecimal?: boolean;
}

export interface FieldValidationProps {
    validationFunction?: (value: string | number) => Mary.organisms.ValidationFeedback;
    readonly?: boolean;
    disabled?: boolean;
    required?: boolean;
    unsorted?: boolean;
}

interface FieldProps extends Pick<ReactDatePickerProps, "popperPlacement"> {
    label?: string;
    placeholder?: string;
    onChange?: (value?: string | number) => void;
    asSummary?: boolean;
}

/**
 *
 */
type Props = Mary.theme.ThemeConsumerProps &
FieldProps &
FieldValidationProps &
FieldValueProps;

/**
 *
 * @param props
 */
const FormFieldComp: React.FunctionComponent<Props> = (props) => (
    <FormGroup
        {...props}
        className={props?.validationFunction?.(props.value ?? "").status === ValidationStatus.SUCCESS
            ? "scl-h-state--success"
            : props?.validationFunction?.(props.value ?? "").status === ValidationStatus.ERROR
                ? "scl-h-state--danger" : ""}>
        {!props.options
            ?
            <Mary.atoms.Input
                placeholder={props.placeholder}
                value={props.value}
                onChange={!!props.readonly ? undefined : props.onChange}
                type={props.type || "text"}
                disabled={!!props.readonly ? true : props.onChange ? false : true}
                min={props.min}
                max={props.max}
                required={props.required}
                title={props.title}
                asSummary={props.asSummary}
                popperPlacement={props.popperPlacement}
                minDate={props.minDate}
                maxDate={props.maxDate}
                withPrefix={props.withPrefix}
                withoutDecimal={props.withoutDecimal}
            />
            : <Mary.atoms.Select
                required={props.required}
                placeholder={props.placeholder}
                unsorted={props.unsorted}
                value={props.value}
                values={props.values}
                onChange={!!props.readonly ? undefined : props.onChange}
                options={props.options}
                defaultValue={undefined}
                disabled={!!props.readonly ? true : props.onChange ? false : true}
                multiple={props.multiple}
            />}
        {props?.validationFunction?.(props.value ?? "") &&
                (
                    <div className="scl-o-form__info">
                        <div className="scl-o-form__info-text">
                            {props?.validationFunction?.(props.value ?? "").message}
                        </div>
                        <div className="scl-o-form__info-icon">
                            {props?.validationFunction?.(props.value ?? "").status === ValidationStatus.SUCCESS
                                ? <i className="fa fa-check" /> : <i className="fa fa-exclamation-triangle" />}
                        </div>
                    </div>
                )
        }
    </FormGroup>
);

/**
 *
 */
export const FormField = Mary.theme.asThemeConsumer(FormFieldComp);
