import { mapInitResponse } from "./Init/RemoteConfig";
import { mapFormMaxLeaseLoanResponse } from "./MaxLeaseLoan/RemoteConfig";
import { remoteClearError, remoteClearResponse } from "./RemoteActions";
import {
    RemoteConfig,
    RemoteScope,
} from "./RemoteTypes";

/**
 *
 */
export const RemoteConfigs: { [K in RemoteScope]: RemoteConfig<K>; } = {
    [RemoteScope.INIT]: {
        scope: RemoteScope.INIT,
        method: "GET",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.INIT));
            d(remoteClearError(RemoteScope.INIT));
        },
        paramsMapper: (_s, ctx) =>  ({
            channel: ctx.channel,
        }),
        pathMapper: (_s, ctx) => {
            if(ctx.route === "max-lease") {
                return "/maxLease";
            } else if(ctx.route === "max-loan") {
                return "/maxLoan";
            } else {
                return "";
            }
        },
        resMapper: mapInitResponse,
    },
    [RemoteScope.MAX_LEASE_POST]: {
        scope: RemoteScope.MAX_LEASE_POST,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MAX_LEASE_POST));
            d(remoteClearError(RemoteScope.MAX_LEASE_POST));
        },
        paramsMapper: (_s, ctx) =>  ({
            channel: ctx.channel ?? "",
        }),
        pathMapper: (_s, _ctx) => "/maxLease",
        bodyMapper: (_s, ctx) => ctx.form,
        resMapper: mapFormMaxLeaseLoanResponse,
    },
    [RemoteScope.MAX_LOAN_POST]: {
        scope: RemoteScope.MAX_LOAN_POST,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.MAX_LOAN_POST));
            d(remoteClearError(RemoteScope.MAX_LOAN_POST));
        },
        paramsMapper: (_s, ctx) =>  ({
            channel: ctx.channel ?? "",
        }),
        pathMapper: (_s, _ctx) => "/maxLoan",
        bodyMapper: (_s, ctx) => ctx.form,
        resMapper: mapFormMaxLeaseLoanResponse,
    },
};
