/* eslint-disable max-len */
import { Map } from "immutable";
import * as React from "react";
import { connect } from "react-redux";

import { Home } from "./App/components/09-views/02-pages/Home";
import { DispatchFunc } from "./store/ActionTypes";
import { ReduxState, RemoteErrors } from "./store/ReduxState";
import { remoteClearError } from "./store/RemoteActions";
import { RemoteErrorType, RemoteScope } from "./store/RemoteTypes";
import { RouteComponentProps } from "react-router-dom";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { Blockquote } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms";
import { ReleaseVersion } from "./App/components/05-atoms/ReleaseVersion";

interface State {
    asPage: boolean;
}

/**
 *
 */
interface DispatchProps {
    handleRemoteError: (scope: RemoteScope, error: RemoteErrors) => void;
}

/**
 *
 */
interface StateProps {
    remoteErrors: Map<RemoteScope, RemoteErrors>;
    theme: Mary.theme.Themes;
    routeProps?: string;
}

interface OwnProps {
    apiEndPoint?: string;
    setTheme: (to: Mary.theme.Themes) => void;
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

const handleRemoteErrorModal = (id: string, onClose: () => void, error: RemoteErrors) => {
    const modal = (
        <Mary.views.modals.ModalNotification
            id={id}
            title={error.type === RemoteErrorType.VALIDATION
                ? Mary.utils.getText("app.notification", "Notification")
                : Mary.utils.getText("app.warning", "Warning")}
            onCloseModal={onClose}
            theme={{
                paletteState: error.type !== RemoteErrorType.VALIDATION
                    ? error.type === RemoteErrorType.UNKNOWN_ERROR
                        || error.type === RemoteErrorType.DEFAULT
                        ? Mary.theme.ThemePaletteState.DANGER : Mary.theme.ThemePaletteState.WARNING
                    : undefined,
                palette: error.type === RemoteErrorType.VALIDATION ? Mary.theme.ThemePalette.CONTRAST_TERTIARY
                    : undefined,
            }}
        >
            <React.Fragment>
                <Blockquote
                    style={{
                        maxHeight: "200px",
                        overflowY: "scroll",
                        wordWrap: "break-word",
                        whiteSpace: "pre-line",
                    }}
                >
                    <Mary.organisms.RTE>
                        <h5>
                            {error.type !== RemoteErrorType.UNKNOWN_ERROR ?
                                Mary.utils.getText("app.remote-error", "Claude can't continue with your request.") :
                                Mary.utils.getText("app.fatal-remote-error", "Even Claude doesn't know what went wrong here!")
                            }
                        </h5>
                        {error.message}
                    </Mary.organisms.RTE>
                </Blockquote>
            </React.Fragment>
        </Mary.views.modals.ModalNotification>
    );
    // eslint-disable-next-line react/display-name
    return () => modal;
};

const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    handleRemoteError: (scope, error) => dispatch(
        Mary.utils.modalShow("handleremoteerror", handleRemoteErrorModal("handleremoteerror", () => {
            dispatch(Mary.utils.modalClose("handleremoteerror"));
            dispatch(remoteClearError(scope));
        }, error))),
});

const mapStateToProps = (s: ReduxState, p?: RouteComponentProps): StateProps => ({
    remoteErrors: s.prop("remoteErrors"),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    theme: s.prop("currentTheme"),
    routeProps: p?.location?.pathname,
});

class AppComp
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = { asPage : true };

        this.checkRemoteError = this.checkRemoteError.bind(this);
        this.inIframe = this.inIframe.bind(this);
    }

    public componentDidMount() {
        this.checkRemoteError();
        const pathname = this.props.routeProps?.substring(1).split("/")?.[0];
        if(pathname) {
            Object.values(Mary.theme.Themes).map(v => {
                if(pathname === v) {
                    this.props.setTheme(v);
                }
            });
        }

        if(this.inIframe()) {
            this.setState({
                asPage: false,
            });
        }
    }

    public componentDidUpdate() {
        this.checkRemoteError();
    }

    /**
     *
     */
    public render() {
        return ( !this.state.asPage ? <Home asPage={this.state.asPage} routeProps={this.props.routeProps?.substring(1).split("/")?.[1]} /> : (
            <Mary.base.flex.FlexRow
                theme={{
                    palette: Mary.theme.ThemePalette.CONTRAST_SECONDARY,
                }}
            >
                <Mary.base.Container>
                    <Mary.base.Div className="scl-b-row">
                        <Mary.base.Grid
                            offset={{sm: 2 }}
                            size={{sm: 8}}
                        >
                            <Mary.base.Div
                                theme={{
                                    padding: {
                                        "": { y: 3 },
                                        "sm": { y: 4 },
                                    },
                                }}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Mary.base.BaseConsumer>
                                    { ({getTheme}) => (
                                        <Mary.base.Logo
                                            variant={Mary.theme.ThemeVariants.BRAND}
                                            theme={getTheme()}
                                            style={{ height: "35px", margin: "0 auto" }}
                                        />
                                    )}
                                </Mary.base.BaseConsumer>
                            </Mary.base.Div>
                            <Home asPage={this.state.asPage} routeProps={this.props.routeProps?.substring(1).split("/")?.[1]}/>
                            <Mary.base.Div
                                theme={{
                                    padding: {
                                        "": { b: 3 },
                                        "sm": { b: 4 },
                                    },
                                }}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Mary.base.BaseConsumer>
                                    { ({getTheme}) => (
                                        <Mary.base.Logo
                                            variant={getTheme() === Mary.theme.Themes.DUTCHLEASE
                                                ? Mary.theme.ThemeVariants.CONTRAST : Mary.theme.ThemeVariants.BRAND}
                                            theme={Mary.theme.Themes.CLAUDE}
                                            style={{ height: "24px", margin: "0 auto" }}
                                        />
                                    )}
                                </Mary.base.BaseConsumer>
                                <Mary.base.Div
                                    theme={{
                                        padding: {
                                            "": { t: 1 },
                                            "sm": { t: 2 },
                                        },
                                    }}
                                >
                                    <ReleaseVersion />
                                </Mary.base.Div>
                            </Mary.base.Div>
                        </Mary.base.Grid>
                    </Mary.base.Div>
                </Mary.base.Container>
            </Mary.base.flex.FlexRow>
        ));
    }

    private inIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    };

    private checkRemoteError = () => {
        const errorEntry = this.props.remoteErrors.findEntry((val) => !!val);
        if (!!errorEntry) {
            const [scope, reason] = errorEntry;
            this.props.handleRemoteError(scope, reason);
        }
    };
}


/**
 *
 */
const AppWrapper: React.FunctionComponent<OwnProps> = props => (<AppComp {...props as Props} />);

/**
 *
 */
export const App = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppWrapper);
