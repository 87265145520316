import React from "react";
import { connect } from "react-redux";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { Init } from "./Init";

interface Props {
    asPage: boolean;
    routeProps?: string;
}

class HomeComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
    }

    public render() {
        return (
            <Mary.base.Div
                theme={{
                    padding: {
                        "": { b: 3 },
                        "sm": { b: 4 },
                    },
                }}
            >
                <Mary.molecules.EnvIndicatorBar />
                <Init asPage={this.props.asPage} routeProps={this.props.routeProps}/>
            </Mary.base.Div>
        );
    }
}

export const Home = connect(
    undefined,
    undefined,
)(HomeComp);


