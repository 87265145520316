/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React from "react";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { FormField } from "../../../07-organisms/Form/Field";
import { Field, FieldType, InitType } from "../../../../../store/Init/Types";
import { FormMaxLeaseLoanField,
    FormMaxLeaseLoanObject,
    FormMaxLeaseLoanObjectResponse, MaxLeaseLoanValidations } from "../../../../../store/MaxLeaseLoan/Types";
import { Required } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms/Required";
import { ValidationStatus } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/07-organisms/Form/_Types";
import { FormGroup } from "../../../07-organisms/Form/Group";
import { mapFieldsForSubmit, validateFieldForSubmit } from "../../../../utils/Submit";

interface Props {
    init: InitType;
    onSubmit: (body: FormMaxLeaseLoanObject, channel: string) => void;
    submitLoading: boolean;
    result?: FormMaxLeaseLoanObjectResponse;
}

export const Form: React.FunctionComponent<Props> = ({init, onSubmit, submitLoading, result }) => {
    const formfieldsMapped: FormMaxLeaseLoanField[] = init?.fields && Object.entries(init?.fields).map(f => ({
        id: f[0], // key
        field: {
            ...f[1],
            value: f[1].value === null ? undefined : f[1].value,
            show: f[1].dependencies ? false : true,
        }, // field object
    }));
    const [store, setStore] = React.useState({fields: [] as FormMaxLeaseLoanField[], validations: {}});
    const url = new URL(window.location.href);
    const channel = url.searchParams.get("channel");
    React.useEffect(() => {
        setStore({
            fields: formfieldsMapped,
            validations: {},
        });
    }, []);
    const updatedValues = (
        fields: FormMaxLeaseLoanField[],
        fieldId: string,
        field: {value: Field["value"]; type: Field["fieldType"]; label: Field["title"]}) => fields?.map(f => {
        const dependentValues = f.field.dependencies && Object.values(f.field.dependencies);
        if(fieldId === f.field.id) {
            return {
                id: f.id,
                field: {
                    ...f.field,
                    value: field.value,
                },
            };
        } else {
            return {
                id: f.id,
                field: {
                    ...f.field,
                    show: (f.field.dependencies && fieldId in f.field.dependencies
                        ? (dependentValues?.flat().includes(field.value) ? true : false) : f.field.show),
                    value: (f.field.dependencies && fieldId in f.field.dependencies
                        ? (dependentValues?.flat().includes(field.value) ? f.field.value : undefined) : f.field.value),
                },
            };
        }
    });
    const onChange = (
        fieldId: string,
        field: {
            value: Field["value"];
            type: Field["fieldType"];
            label: Field["title"];
        }) => {
        setStore({
            fields: updatedValues(store.fields, fieldId, field),
            validations: validateFieldForSubmit(store.fields, store.validations ?? {}, fieldId, field),
        });
    };
    const handleSubmit = () => {
        onSubmit(mapFieldsForSubmit(store.fields), channel ?? "");
    };
    const isDisabled = () => {
        const requiredFields = store.fields.filter(f => f.field.required === true && f.field.show === true);
        const requiredFieldsAreValid = requiredFields.map(r =>
            r.id in store.validations && store.validations[r.id]["status"] === ValidationStatus.SUCCESS);
        return requiredFieldsAreValid.includes(false) ? true : false;
    };
    return (
        <Mary.organisms.Form onSubmit={submitLoading ? undefined : handleSubmit}>
            {store.fields?.map((f, i) => (
                (f.field.fieldType === FieldType.CURRENCY && f.field.show)
                    ? <Mary.base.Div className="scl-b-row" key={`${f.id}-${i}-currency`}><Mary.base.Grid
                        size={{ xs: 12, md: 12 }}
                    >
                        <FormField
                            popperPlacement={"top"}
                            label={f.field.title}
                            title={f.field.description}
                            type={f.field.fieldType}
                            disabled={submitLoading}
                            placeholder="e.g. 100"
                            required={f.field.required}
                            value={f.field.value as number}
                            withPrefix
                            withoutDecimal
                            onChange={(value?: string | number) => {
                                onChange(f.field.id, {
                                    value: value ? typeof value === "string"
                                        ? parseFloat(value) : value : 0,
                                    type: f.field.fieldType,
                                    label: f.field.title,
                                });
                            }}
                            validationFunction={(f.field.value !== undefined && store.validations[f.field.id])
                                ? () => store.validations[f.field.id] : undefined}
                        />
                    </Mary.base.Grid></Mary.base.Div>
                    : (f.field.fieldType === FieldType.SELECT && f.field.show)
                        ? <Mary.base.Div className="scl-b-row" key={`${f.id}-${i}-select`}><Mary.base.Grid
                            size={{ xs: 12, md: 12 }}
                        >
                            <FormField
                                popperPlacement={"top"}
                                label={f.field.title}
                                title={f.field.description}
                                type={f.field.fieldType}
                                disabled={submitLoading}
                                required={f.field.required}
                                placeholder={Mary.utils.getText("app.make-a-choice", "Make a choice")}
                                unsorted
                                value={f.field.value as string}
                                options={f.field.options}
                                onChange={(value?: string | number) => {
                                    onChange(f.field.id, {
                                        value: value?.toString() ?? "",
                                        type: f.field.fieldType,
                                        label: f.field.title,
                                    });
                                }}
                                validationFunction={(f.field.value !== undefined && store.validations[f.field.id])
                                    ? () => store.validations[f.field.id] : undefined}
                            />
                        </Mary.base.Grid></Mary.base.Div>
                        : (f.field.fieldType === FieldType.BOOLEAN && f.field.show)
                            ? <><Mary.base.Div className="scl-b-row" key={`${f.id}-${i}-boolean`}><Mary.base.Grid
                                size={{ xs: 12, md: 12 }}
                                theme={{
                                    padding: {
                                        "": { b: 1 },
                                        "sm": { b: 2 },
                                    },
                                }}
                            >
                                <Mary.atoms.Label>
                                    {f.field.title}
                                    {f.field.required === true &&
                                        (<span className={"scl-a-label__field--as-required"}>*</span>)}
                                    {f.field.description ?
                                        <Mary.base.Div className="scl-a-tooltip__wrapper">
                                            <Mary.atoms.Icon name="info" className="scl-a-tooltip__trigger" />
                                            <Mary.atoms.Tooltip>{f.field.description}</Mary.atoms.Tooltip>
                                        </Mary.base.Div> : null}
                                </Mary.atoms.Label>
                                <Mary.atoms.radiobtn.RadioBtn
                                    checked={f.field.value?.toString() === "true" ? true : false}
                                    label={f.field.options?.[0].label}
                                    disabled={submitLoading}
                                    onChange={(value?: string) => onChange(f.field.id, {
                                        value: value === "Yes" ? true : false,
                                        type: f.field.fieldType,
                                        label: f.field.title,
                                    })}
                                    name={f.field.title}
                                    value={"Yes"} />
                                <Mary.atoms.radiobtn.RadioBtn
                                    checked={f.field.value?.toString() === undefined
                                        ? false
                                        : !f.field.value}
                                    label={f.field.options?.[1].label}
                                    disabled={submitLoading}
                                    onChange={(value?: string) => onChange(f.field.id, {
                                        value: value === "No" ? false : true,
                                        type: f.field.fieldType,
                                        label: f.field.title,
                                    })}
                                    name={f.field.title}
                                    value={"No"} />
                            </Mary.base.Grid></Mary.base.Div>
                            <FormGroup key={`${f.id}-${i}-status`}
                                className={store.validations[f.field.id]?.status === ValidationStatus.SUCCESS
                                    ? "scl-h-state--success"
                                    : store.validations[f.field.id]?.status === ValidationStatus.ERROR
                                        ? "scl-h-state--danger" : ""}>
                                <div className="scl-o-form__info">
                                    <div className="scl-o-form__info-text">
                                        {store.validations[f.field.id]?.message}
                                    </div>
                                    <div className="scl-o-form__info-icon">
                                        {store.validations[f.field.id]?.status === ValidationStatus.SUCCESS
                                            ? <i className="fa fa-check" /> : ""}
                                    </div>
                                </div>
                            </FormGroup></>
                            : <></>
            ))}
            <Mary.base.Div theme={{margin: {
                "": { t: -1, b: 2 },
                "sm": { t: -1, b: 3 },
            }}}>
                <Mary.atoms.Blockquote>
                    <div style={{ marginBottom: "15px"}}>
                        <Mary.atoms.Heading level={Mary.atoms.Headings.H1} headingStyle={Mary.atoms.Headings.H5}>
                            {init?.summary?.totalAmountLabel}
                        </Mary.atoms.Heading>
                        <div style={{ marginTop: "15px"}}>
                            <Mary.atoms.Heading level={Mary.atoms.Headings.H1} headingStyle={Mary.atoms.Headings.H3}>
                                {result?.totalAmountValue ? <Mary.atoms.Currency amount={+(result?.totalAmountValue)}/> : "-"}
                            </Mary.atoms.Heading>
                        </div>
                    </div>
                    <Mary.organisms.RTE content={init?.summary?.disclaimer} />
                </Mary.atoms.Blockquote>
            </Mary.base.Div>
            <div className="scl-h-text-align--right"><Required /></div>
            {Object.keys(store.validations)
                .some(v => store.validations[v as keyof MaxLeaseLoanValidations]?.status === ValidationStatus.ERROR )
                && (
                    <Mary.base.Div className="scl-b-row"
                        theme={{margin: {
                            "": { b: 2 },
                            "sm": {b: 3 },
                        }}}
                    >
                        <Mary.atoms.Blockquote
                            theme={{
                                paletteState: Mary.theme.ThemePaletteState.DANGER}}
                            background
                            className="scl-h-foreground--contrast-state-danger"
                            style={{position: "relative"}}>
                            <h5><Mary.atoms.Icon style={{
                                fontSize: ".75em",
                                verticalAlign: "middle",
                                marginRight: "5px",
                            }} name={"exclamation-triangle"}/>&nbsp;Niet alle velden zijn correct ingevuld!</h5>
                            <p>Om verder te gaan moeten de volgende velden (goed) ingevuld zijn.</p><br/>
                            <ul>
                                {Object.values(store.validations)
                                    .filter((f: any) => f["status"] === ValidationStatus.ERROR).map((k: any, index) => (
                                        <li key={`${k}-${index}-validations}`}>
                                            <Mary.atoms.Icon style={{
                                                fontSize: "8px",
                                                verticalAlign: "middle",
                                                marginRight: "5px",
                                                marginLeft: "10px",
                                            }} name={"circle"}/>&nbsp;{k["title"]}
                                        </li>
                                    )
                                    )}
                            </ul>
                            <Mary.base.Div style={{
                                position: "absolute",
                                top: "100%",
                                left: "50%",
                                transform: "translateX(-50%)",
                                borderWidth: "8px",
                                borderStyle: "solid",
                                fontSize: "0",
                                borderColor: "var(--scl-color-state-brand--danger) transparent transparent transparent",
                            }}>&nbsp;</Mary.base.Div>
                        </Mary.atoms.Blockquote>
                    </Mary.base.Div>
                )}
            <Mary.molecules.ButtonsWrapper
                orientations={{ [Mary.theme.ThemeBreakpoints.XS]: Mary.molecules.Orientation.HORIZONTAL}}
                alignment={Mary.molecules.Alignment.LEFT}
            >
                <Mary.base.Div style={{display: "flex"}}>
                    <Mary.atoms.button.Button
                        className="scl-a-btn--big"
                        theme={{
                            palette: Mary.theme.ThemePalette.BRAND_ACCENT,
                        }}
                        buttonType="submit"
                        disabled={isDisabled() || submitLoading}
                    >
                        {submitLoading ?
                            <div style={{ height: "20px", display: "flex", alignItems: "center" }}>
                                <Mary.atoms.loadingindicator.LoadingIndicator
                                    type={Mary.atoms.loadingindicator.LoadingIndications.DEFAULT}
                                    theme={{ palette: Mary.theme.ThemePalette.CONTRAST_PRIMARY }}
                                />
                            </div> : init?.buttonTitle}
                    </Mary.atoms.button.Button>
                </Mary.base.Div>
            </Mary.molecules.ButtonsWrapper>
        </Mary.organisms.Form>
    );
};
